<script setup>
    import FiltersList from 'o365.vue.components.Filters.List.vue';

    import { defineProps,ref} from "vue";
    const props = defineProps({
        dataObject: Object,
        dropdown: null
    });

    const isOpen = ref(false);

    const show = (pFilter)=>{
      
    }

    if(!props.dataObject.filterObject.filtersListDO.state._isLoaded){
        props.dataObject.filterObject.filtersListDO.load();
    }

    const filterComp = ref({
        show:show
    })

    defineExpose({isOpen});

</script>

<template>
    <template v-if="!!dropdown">
        <FiltersList  :filterObject="dataObject.filterObject" :filter-componenent="filterComp" :dropdown="dropdown" v-slot="scope"></FiltersList>
        
        <li v-if="dataObject.filterObject.filtersListDO.data.filter(x=>!x.mine).length"><h6 class="dropdown-header">{{$t('Shared Filters')}}</h6></li>
        <FiltersList  v-if="dataObject.filterObject.filtersListDO.data.filter(x=>!x.mine).length" :filterObject="dataObject.filterObject" :filter-componenent="filterComp" :dropdown="dropdown" v-slot="scope" sharedOnly></FiltersList>

    </template>
    <template v-else>
        <h5>{{$t('My Filters')}}</h5>

        <button class="btn btn-default" @click="show(null)">{{$t('Add Filter')}}</button>
        <FiltersList :filterObject="dataObject.filterObject"  :filter-componenent="filterComp"></FiltersList>
        <FiltersList :filterObject="dataObject.filterObject"  :filter-componenent="filterComp" sharedOnly></FiltersList>

   
    </template>
</template>
